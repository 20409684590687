.app--check {
	margin: 12px 0 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	&:not(.uses-children) {
		cursor: pointer;
		&:not(.checked):not(:disabled):hover {
			.app--check-trigger {
				background: lighten($primaryBg, 14);
				border-color: lighten($primaryBg, 14);
			}
			.app--check-label {
				color: darken($textWhite, 6);
			}
		}
	}
	&.uses-children:not(.checked) {
		.app--check-trigger:not(:disabled):hover {
			background: lighten($primaryBg, 14);
			border-color: lighten($primaryBg, 14);
		}
	}
	&.checked {
		.app--check-trigger {
			background: $primary;
			border-color: $primary;
			svg {
				opacity: 1;
			}
		}
		.app--check-label {
			color: darken($textWhite, 6);
		}
	}
	&.invalid {
		.app--check-trigger {
			border-color: $danger !important;
		}
	}
}

.app--check-trigger {
	width: 22px;
	height: 22px;
	cursor: pointer;
	box-shadow: 0 2px 4px rgba(darken($backgroundPrimary, 1), .4);
	background: lighten($primaryBg, 12);
	transition: background-color .2s ease, border-color .2s ease;
	flex-shrink: 0;
	border: 1px solid lighten($primaryBg, 12);
	-webkit-backface-visibility: hidden;
	-webkit-transform: perspective(22px);
	div {
		font-size: 0;
	}
	svg {
		fill: #000;
		opacity: 0;
		transition: opacity .2s ease;
		pointer-events: none;
	}
}

.app--check-label,
.app--check-content {
	margin: 0 0 0 12px;
	color: darken($textWhite, 25);
	font-size: 15px;
	font-weight: 600;
	transition: color .2s ease;
	user-select: none;
}

.app--check-content {
	color: $textGrey;
	line-height: 1.4;
}

$primary: #fcf1bd;
$secondary: #b89e5e;
$white: #fff;
$grey: #383838;
$darkGrey: #42484d;
$danger: #e02e2e;
$danger2: #d3465a;
$backgroundPrimary: #080808;
$backgroundSecondary: lighten($backgroundPrimary, 4);
// $backgroundTertiary: lighten($backgroundPrimary, 18);
$backgroundTertiary: #2e2e2e;

$font: 'Open Sans';

$textGrey: #9b9b9b;
$textWhite: #fff;
$textBlack: #000;

$asideWidth: 300px;
$mainWidth: 720px;

$editModalWidth: 760px;

$iconFillColor: #fff;

.search {
	position: relative;
	.icon-container {
		.loader, svg {
			position: absolute;
			top: 17px;
			left: 17px;
			@include backface(16px);
		}
		svg {
			opacity: 1;
			pointer-events: none;
			transition: opacity .2s ease;
		}
	}
	input {
		padding-left: 50px + 18px;
	}
	.icon-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 50px;
		height: 50px;
		&:before {
			content: '';
			width: 2px;
			height: 100%;
			position: absolute;
			pointer-events: none;
			top: 0;
			right: -1.5px;
			background-color: $backgroundPrimary;
			transform: skewX(-6deg);
		}
	}
	.app-square-icon {
		position: relative;
	}
	.search-addons {
		position: absolute;
		top: 11px;
		right: 11px;
		display: flex;
		*:not(:last-child) {
			margin: 0 9px 0 0;
		}
		.app-select--container {
			svg {
				right: 8px;
				path {
					fill: #fff !important;
				}
			}
		}
		.app-select--element {
			padding: 4.5px 8px;
			min-height: auto;
			padding-right: 16px;
			background-color: lighten($backgroundTertiary, 4);
			&:not(:disabled) {
				cursor: pointer;
			}
		}
		button {
			position: relative;
			width: 28px;
			height: 28px;
			min-width: 28px;
			min-height: 28px;
			background-color: lighten($backgroundTertiary, 4);
			&.clickable:not(:disabled):hover {
				background-color: lighten($backgroundTertiary, 6);
			}
			svg {
				fill: #fff !important;
				position: static;
				width: 12px;
				height: 12px;
			}
		}
	}
	&.with-addon input {
		padding-right: 100px;
		text-overflow: ellipsis;
	}
	&.resetable.with-addon input {
		padding-right: 132px;
		text-overflow: ellipsis;
	}
}

.search-loading .icon-container svg {
	opacity: 0;
}

.app-modal,
.app-modal--bg {
	position: fixed;
	z-index: 1002;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.app-modal {
	display: flex;
	padding: 40px;
	overflow-y: auto;
	overflow-x: hidden;
	pointer-events: none;
	.app-button {
		width: 100%;
	}
}

.app-modal--bg {
	background: rgba(#000, .7);
	backdrop-filter: blur(25px);
}

.app-modal--content {
	width: 375px - 40px;
	padding: 20px;
	position: relative;
	text-align: left;
	background: $backgroundSecondary;
	border-radius: 0;
}

.app-modal--header {
	margin: 0 0 28px;
	display: flex;
	position: relative;
	min-height: 50px;
	align-items: center;
	justify-content: center;
	svg {
		fill: $iconFillColor;
	}
	.app-square-icon {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.app-modal--bg,
.app-modal--wrap {
	opacity: 0;
	transition: opacity .2s ease, transform .2s ease;
	pointer-events: none;
}

.app-modal--wrap {
	text-align: center;
	position: relative;
	z-index: 1003;
	margin: auto;
	transform: translateY(10px);
}

.visible {
	pointer-events: auto;
	.app-modal--bg,
	.app-modal--wrap {
		opacity: 1;
		pointer-events: auto;
	}
	.app-modal--wrap {
		transform: translateY(0);
	}
}

.stripe--modal {
	.app-button {
		margin: 20px 0 0;
	}
	.stripe-error {
		color: $danger;
		margin: 12px 0 0;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
	}
	.app-modal--content {
		min-height: 327px;
	}
}

.video-record--modal,
.rating--modal {
	.app-modal--content p {
		color: darken(#fff, 6);
		margin: 0 0 22px;
		text-align: center;
		font-size: 15px;
		font-weight: 600;
		line-height: 1.4;
	}
	p.error {
		color: $danger;
		font-size: 14px;
		font-weight: 500;
	}
	.app-button:not(:last-child) {
		margin-bottom: 20px;
	}
}

body.overflowed {
	overflow: hidden;
}

.modal--eula {
	.app-modal--header {
		margin: 0;
		padding: 20px 20px 28px;
		justify-content: flex-start;
		.app-square-icon {
			position: static;
		}
	}
	.app-modal--content {
		width: 800px;
		padding: 0;
		position: relative;
	}
	.modal-actions {
		padding: 30px;
		background: $backgroundPrimary;
		display: flex;
		.app-button:first-child {
			margin: 0 28px 0 0;
		}
	}
	iframe {
		width: 100%;
		height: calc(100vh - 80px - 98px - 30px - 108px);
		display: block;
	}
	@media screen and (max-width: 880px) {
		.app-modal--wrap,
		.app-modal--content {
			width: 100%;
		}
	}
}

.export--modal .app-modal--content {
	p.message {
		color: $textGrey;
		margin: 20px 0;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
	}
	.app-button[class*='primary'] {
		margin: 0 0 20px;
	}
	.app--flag {
		width: 50%;
		margin: 0 20px 0 0;
		&:last-child {
			margin: 0;
		}
	}
	.app--flag-icon:before {
		padding: 0;
	}
	.flags {
		margin: 0 0 20px;
		display: flex;
		font-size: 0;
	}
}

.support--modal,
.campaign--modal {
	p.message {
		color: darken($textWhite, 6);
		margin: 0 0 20px;
		font-size: 15px;
		font-weight: 500;
		text-align: center;
		line-height: 1.5;
	}
	.app-button:not(:last-child) {
		margin-bottom: 20px;
	}
}

.campaign--modal .app-button:not(:last-child) {
	margin: 0 0 20px;
}

@media screen and (max-width: 414px) {
	.app-modal {
		padding: 22px;
		min-width: 320px;
	}
	.app-modal--wrap,
	.app-modal--content {
		width: 100%;
	}
	.app-modal--content {
		margin: 0 auto;
	}
}

.app-over-item .app-over-item__inner.send-push {
	width: 540px;
	border: 1px solid $backgroundSecondary;
	padding: 0;
	background: $backgroundPrimary;
	border-radius: 0;
}

.app-over-item .app-over-item__inner.create-push {
	width: 540px;
}

.send-push {
	position: relative;
	form {
		font-size: 0;
	}
	.modal--footer {
		width: 100%;
		bottom: -40px;
		padding: 22px;
		position: sticky;
		background: rgba($backgroundTertiary, .4);
		border-top: 1px solid lighten($backgroundTertiary, 6);
		border-radius: 0;
		backdrop-filter: blur(10px);
		.app-button {
			width: 100%;
		}
	}
}

.send-push--head {
	padding: 22px;
	background: lighten($backgroundPrimary, 1);
	border-bottom: 1px solid $backgroundSecondary;
	display: flex;
	align-items: center;
	h1 {
		color: darken($textWhite, 6);
		margin: 0 auto 0 16px;
		font-size: 24px;
		font-weight: 700;
	}
}

.send-push--body {
	padding: 22px;
	.app--flag {
		width: 100%;
		display: block;
		&:not(:last-child) {
			margin: 0 0 18px;
		}
	}
	.app-input:not(:last-child),
	.app-legend:not(:last-child) {
		margin: 0 0 22px;
	}
	.field--wrapper {
		padding: 22px;
	}
	.app-button {
		width: 100%;
	}
	.pages {
		margin: 0 auto;
		justify-content: center;
	}
	.filters {
		margin: 12px 0 22px;
		display: flex;
		.app-select.compact {
			width: 100%;
			margin: 0 12px 0 0;
			.app-select--container {
				width: 100%;
			}
			&:last-child {
				margin: 0;
			}
		}
	}
	.message {
		color: darken($textGrey, 12);
		font-size: 15px;
		text-align: center;
		font-weight: 700;
		line-height: 1.6;
		i {
			color: $primary;
			font-style: normal;
		}
		&:not(:last-child) {
			margin: 0 0 18px;
		}
	}
	.app-option-view--value {
		text-align: left;
	}
}

.app--user-compact {
	border: 1px solid lighten($backgroundTertiary, 4);
	display: flex;
	padding: 12px;
	background: $backgroundSecondary;
	box-shadow: 0 1px 4px darken($backgroundSecondary, 6);
	align-items: center;
	border-radius: 0;
	justify-content: space-between;
	&:not(:last-child) {
		margin: 0 0 22px;
	}
	&.is-selectable {
		cursor: pointer;
		transition: border-color .2s ease, background .2s ease;
		&:not(.is-selected):hover {
			border-color: lighten($backgroundTertiary, 8);
			background-color: lighten($backgroundSecondary, 2);
		}
	}
	&.is-selected {
		border-color: $primary;
		background-color: rgba($primary, .05);
	}
	.avatar-empty {
		background: lighten($backgroundTertiary, 8);
	}
	.app--flag {
		width: 20px;
		align-self: flex-start;
		flex-shrink: 0;
	}
	.content {
		margin: 0 12px;
		min-width: 0;
		flex-grow: 1;
		flex-shrink: 1;
		h4, p {
			overflow: hidden;
			text-overflow: ellipsis;
		}
		h4 {
			color: darken($textWhite, 6);
			margin: 0 0 2px;
			font-size: 15px;
			font-weight: 700;
		}
		p {
			color: $primary;
			font-size: 14px;
			font-weight: 600;
		}
	}
}

.app--r-select {
	display: flex;
	align-items: flex-start;
	.app-square-icon {
		svg {
			width: 18px;
		}
	}
	.app--r-select-container {
		width: 100%;
		display: flex;
		align-items: stretch;
		flex-direction: column;
	}
	p.note {
		margin: 10px 0 0;
		color: $textGrey;
		text-align: right;
		font-size: 13px;
		font-weight: 600;
	}
	&.invalid {
		[class$=control] {
			border-color: $danger !important;
		}
	}
	.app-square-icon {
		margin: 0;
		position: relative;
		min-width: 55px;
		background: $backgroundTertiary;
		box-shadow: none;
		border-radius: 0;
		&:before {
			content: '';
			width: 2px;
			height: 100%;
			position: absolute;
			z-index: 20;
			top: 0;
			right: 0;
			transform: skewX(-6deg);
			background: $backgroundSecondary;
			pointer-events: none;
		}
	}
}

.app--input-phone {
	display: flex;
	align-items: flex-start;
	.app-square-icon {
		margin-right: 2px;
		svg {
			fill: $iconFillColor;
		}
	}
	&.invalid {
		.app--input-phone-element {
			border-color: $danger;
		}
	}
	.app-square-icon {
		margin: 0;
		position: relative;
		min-width: 55px;
		background: $backgroundTertiary;
		box-shadow: none;
		border-radius: 0;
		&:before {
			content: '';
			width: 2px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			transform: skewX(-6deg);
			background: $backgroundSecondary;
			pointer-events: none;
		}
	}
	&.textarea .app-square-icon:before {
		transform: none;
	}
}

.app--input-phone-container {
	flex-grow: 1;
}

.app--input-phone-element {
	width: 100%;
	font: 400 14px $font, Sans-serif;
	color: $textWhite;
	background: $backgroundTertiary;
	padding: 15.5px 20px;
	transition: border-color .2s ease;
}

.app-input--message {
	color: $danger;
	font: 500 14px/20px $font, Sans-serif;
	margin: 6px 0 0;
	text-align: right;
}
